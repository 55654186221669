import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OrganicPlantsInStock = () => (
  <Layout>
    <SEO title="Organic Plants In Stock" />
    <h1>Products In Stock</h1>
  </Layout>
)

export default OrganicPlantsInStock;
